.container {
    padding-top: 10vh;
    height: 63vh;
    background: rgba(247, 247, 247, 1);
    display: flex;
    flex-direction: row;
}

.left {
    width: 35%;
}

.text1 {
    width: 90%;
    margin-left: 20vh;
    margin-top: 10vh;
    position: relative;
}

.center {
    width: 30%;
}

.right {
    width: 35%;
}

.text2 {
    width: 79%;
    margin-top: 38vh;
    margin-left: -10vh;
}

@keyframes test {
    0% {
        background-image: url('../../support/homepage/Feature1.png');
    }
    33% {
        background-image: url('../../support/homepage/Feature2.png');
    }
    66% {
        background-image: url('../../support/homepage/Feature3.png');
    }
    100% {
        background-image: url('../../support/homepage/Feature1.png');
    }
}

.image {
    margin-top: 8vh;
    width: 40vh;
    height: 55vh;
    float: left;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-animation: test 4s infinite;
    animation: test 4s infinite;
    -webkit-animation-timing-function: steps(1, end);
    animation-timing-function: steps(1, end);
}