.pageTitle {
  width: 100%;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: bold;
}

.white-box-usermanagement {
  width: 100%;
}

.searchBarUserManagement {
  width: 35%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.createNewUserButton {
  margin-left: 3rem !important;
}

.editAndDeleteButton {
  max-width: fit-content !important;
  max-height: fit-content !important;
  min-width: fit-content !important;
  min-height: fit-content !important;
}

.buttonSize {
  font-size: max(1rem, 1.6vw) !important;
}

.required::after {
  content:" *";
  color: red;
}

.formTitle {
  font-family: "Rubik", sans-serif;
  color: #43425d;
}

.dividerStyle {
  background-color: #afafaf !important;
}

.buttonKeepRight {
  margin-right: 3rem;
  float: right;
}

.buttonCancelMUIStyle {
  color: #f50057 !important;
  border-color: #f50057 !important;
  background: #fff !important;
  border-radius: 4px !important;
}

.buttonCreateMUIStyle {
  border-color: #f50057 !important;
  background: #f50057 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
              0px 2px 2px 0px rgb(0 0 0 / 14%), 
              0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  border-radius: 4px !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
}

.ant-radio-checked .ant-radio-inner{
  border-color: red !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: red;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red ;
}

.slashStyle {
  color: #999999;
  font-size: 1.2rem;
}

.ant-modal-content {
  border-radius: 1rem;
}

.ant-modal-content .ant-modal-body {
  text-align: center;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: none;
  display: flex;
  justify-content: space-around;
}

.ant-modal-confirm-btns .ant-btn {
  width: 8rem;
  color: #f50057 !important;
  border-color: #f50057 !important;
  background: #fff !important;
  border-radius: 4px !important;
}

.ant-modal-confirm-btns .ant-btn-dangerous {
  border-color: #f50057 !important;
  background: #f50057 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
              0px 2px 2px 0px rgb(0 0 0 / 14%), 
              0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  border-radius: 4px !important;
  color: #ffff !important;
}

@media only screen and (max-width: 780px) {
  .buttonSize {
    font-size: 1rem !important;
  }
}

