.roundedbox {
  width: 100%;
  border-radius: 10px;
  text-align: center;
  background: #edf0f5;
  overflow: hidden;
}
.ant-table-thead > tr > th {
  color: #4d4f5c;
  background: #edf0f5 !important;
}

.white-box {
  width: 70vw;
  background-color: white;
  margin: auto;
  border-radius: 15px;
  -webkit-box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.ant-table table {
  font-size: 0.75vw;
}

.title-voucher {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #43425d;
  opacity: 1;
  font-family: "Rubik", sans-serif;
}
.accordion-title {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0px;
  color: #43425d;
  opacity: 1;
}
.desc-voucher {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #4d4f5c;
  text-transform: lowercase;
  opacity: 1;
  font-family: "Rubik", sans-serif;
}
