.container {
  width: 100%;
  height: 100%;
}

.headerContainer {
  width: 100%;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.sidebarDrawer {
  display: none;
}

.headerLogo {
  width: 120px;
  margin: 0;
}

.periodContainerHeader {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-bottom: 10px;
  margin-right: 20px;
}

.dashboardTopPeriodCaptionHeader {
  margin-left: 110px;
  margin-top: 20px;
  margin-right: 30px;
  font-weight: bold;
}

.dashboardTopPickerContainerHeader {
  display: flex;
  flex-direction: row;
}

.dashboardPickerAHeader {
  margin-right: 30px;
}

.logoutButton {
  color: #dd4c5c;
  font-size: 16px;
  display: flex;
  width: 35%;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  font-family: "Rubik", sans-serif;
}

.sidebarContainer {
  width: 15%;
  height: 100%;
  padding: 30px;
}

.dashboardContainer {
  width: 85%;
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;
}

.sidebarContainerMenu {
  display: none;
}

.sidebarContainerInside {
  padding: 30px;
}

.sidebarMenuItemTop {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 30px;
  color: #de4c5d;
}

.sidebarMenuItems {
  margin-top: 30px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 600;
  cursor: pointer;
}

.sidebarMenuChild {
  margin-left: 15px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: mediumblue;
}

.dashboardContainerBox {
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0;
  border-radius: 15px;
  -webkit-box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.dashboardTopCaption {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
}

.dashboardTopPeriodCaption {
  margin-bottom: 10px;
  margin-right: 20px;
}

.dashboardTopPickerContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.dashboardPickerA {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 15px;
  border: 1px solid white;
  margin-right: 30px;
}

.dashboardPickerB {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 15px;
  border: 1px solid white;
}

.dashboardTotalTransactionContainer {
  display: flex;
  flex-direction: row;
}

.dashboardTotalTransaction {
  font-size: 48px;
  font-weight: bold;
  margin-right: 20px;
}

.dashboardTotalTransactionCaption {
  display: flex;
  width: 20px;
  margin-top: 5px;
  justify-content: left;
  align-items: center;
}

.dashboardJumlahTotalContainer {
  display: flex;
  flex-direction: row;
}

.dashboardJumlahTotalCurrency {
  font-size: 36px;
  font-weight: bold;
  margin-right: 10px;
}

.dashboardJumlahTotal {
  font-size: 48px;
  font-weight: bold;
  margin-right: 20px;
}

.dashboardJumlahTotalCaption {
  width: 90px;
  margin-top: 5px;
}

.dashboardRataInvoiceCaption {
  width: 90px;
  margin-top: 5px;
}

.dialogDatePicker {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-bottom: 10px;
  width: 85%;
  align-items: flex-end;
}

.dialogSectionName {
  padding-top: 10px;
  font-weight: 400;
}

.dialogBottomSection {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}

.dashboardChartCaption {
  font-size: 32px;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.dashboardChartContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dashboardChartLeft {
  width: 50%;
}

.dashboardChartRight {
  width: 50%;
}

.dashboardChartLegendContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.dashboardChartLegendCircleOrange {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid orange;
  margin-right: 20px;
}

.dashboardChartLegendCircleYellow {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid yellow;
  margin-right: 20px;
}

.dashboardChartLegendCircleCyan {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid cyan;
  margin-right: 20px;
}

.dashboardChartLegendCircleCrimson {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid crimson;
  margin-right: 20px;
}

.dashboardChartLegendText {
}

.dashboardLegendCaption {
  color: #707070;
}

.dashboardLegendValue {
  font-weight: bold;
}

.periodContainerMobile {
  display: none;
  width: 100%;
}

.periodMobileCaption {
  margin-bottom: 20px;
}

.dashboardContainerMobile {
  height: 100%;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 20px;
  -webkit-box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  width: 100%;
}

.flexMobilePicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.upperMillion {
  font-size: 48px;
}

.lowerMillion {
  font-size: 28px;
  color: rgba(0, 0, 0, 0.5);
}

.profileCompanyHeader {
  font-weight: bold;
  font-size: 1rem;
}

.profileDetailHeader {
  font-size: 0.8rem;
}

.profileContainerHeader {
  margin-left: auto;
  display: flex;
  justify-content: right;
}

.profileDetailContinerHeader {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

@media only screen and (max-width: 780px) {
  .periodContainerMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .logoutButton {
    margin-top: 0px;
    width: 80%;
  }

  .headerLogo {
    margin-top: 0px;
  }

  .periodContainerHeader {
    display: none;
  }

  .dashboardContainerTop {
    height: 25%;
  }

  .sidebarDrawer {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
  }

  .sidebarContainerMenu {
    display: block;
    width: 384px;
  }

  .sidebarContainerInside {
    width: 240px;
  }

  .headerContainer {
    width: 100%;
  }

  .dashboardContainer {
    width: 95%;
    height: 120%;
    /*padding:20px;*/
  }

  .dashboardContainerBottomLeft {
    width: 94%;
    margin-bottom: 20px;
    padding: 20px 10px 20px 20px;
  }

  .dashboardContainerBottomRight {
    width: 94%;
    padding: 20px 10px 20px 20px;
  }

  .sidebarContainer {
    display: none;
  }

  .dashboardContainerBottom {
    display: block;
  }

  .mainContainer {
    height: 130%;
  }
}

@media only screen and (max-width: 480px) {
  .periodContainerMobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .logoutButton {
    margin-top: 0px;
    width: 40%;
  }

  .headerLogo {
    margin-top: 0px;
  }

  .dashboardContainerTop {
    height: 25%;
    width: 90%;
  }

  .sidebarDrawer {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
  }

  .sidebarContainerMenu {
    display: block;
    width: 240px;
  }

  .sidebarContainerInside {
    width: 240px;
  }

  .body {
    height: 120%;
  }

  .headerContainer {
    width: 95.6%;
  }

  .dashboardContainer {
    width: 98%;
    height: 100%;
    /*padding:-20px;*/
  }

  .dashboardContainerBottomLeft {
    width: 93%;
  }

  .dashboardContainerBottomRight {
    width: 93%;
  }

  .sidebarContainer {
    display: none;
  }

  .dashboardContainerBottom {
    display: block;
    width: 100%;
  }

  .mainContainer {
    height: 110%;
  }

  .periodContainerHeader {
    display: none;
    width: 100%;
  }

  .mainContainer {
    height: 130%;
  }
}

@media only screen and (max-width: 361px) {
  .dashboardContainer {
    width: 88.4%;
    height: 105%;
  }

  .headerContainer {
    width: 94.1%;
  }

  .dashboardContainerTop {
    height: 15%;
  }

  .dashboardTopCaption {
    font-size: 24px;
  }

  .dashboardTotalTransaction {
    font-size: 24px;
    margin-top: 5px;
  }

  .dashboardTotalTransactionCaption {
    font-size: 14px;
    margin-top: 5px;
  }

  .upperMillion {
    font-size: 24px;
  }

  .lowerMillion {
    font-size: 16px;
  }

  .dashboardJumlahTotalCaption {
    font-size: 14px;
    margin-top: 15px;
    align-items: center;
  }

  .dashboardJumlahTotal {
    font-size: 24px;
    margin-top: 5px;
  }

  .dashboardJumlahTotalCurrency {
    font-size: 24px;
  }

  .dashboardChartCaption {
    font-size: 24px;
  }

  .dashboardLegendCaption {
    font-size: 14px;
    width: 20px;
  }

  .dashboardRataInvoiceCaption {
    font-size: 14px;
    margin-top: 5px;
  }

  .dialogSectionName {
    padding-top: 10px;
    font-weight: 400;
  }

  .dialogDatePicker {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-bottom: 10px;
    width: 85%;
    align-items: flex-end;
  }

  .dialogBottomSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .periodContainerMobile {
    width: 100%;
  }

  .periodMobileCaption {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
}
