@import url(https://fonts.googleapis.com/css?family=Rubik&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,body{
  height:100%;
}

#root{
  height:100%;
}

.App {
  /*text-align: center;*/
  height:100%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.dashboard_container__cap1n {
  width: 100%;
  height: 100%;
}

.dashboard_headerContainer__1ZqeF {
  width: 100%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.dashboard_sidebarDrawer___rWm9 {
  display: none;
}

.dashboard_headerLogo__38V2R {
  width: 120px;
  margin: 0;
}

.dashboard_periodContainerHeader__mZLRK {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-bottom: 10px;
  margin-right: 20px;
}

.dashboard_dashboardTopPeriodCaptionHeader__D0jHc {
  margin-left: 110px;
  margin-top: 20px;
  margin-right: 30px;
  font-weight: bold;
}

.dashboard_dashboardTopPickerContainerHeader__2rSef {
  display: flex;
  flex-direction: row;
}

.dashboard_dashboardPickerAHeader__3TBFn {
  margin-right: 30px;
}

.dashboard_logoutButton__1TeM5 {
  color: #dd4c5c;
  font-size: 16px;
  display: flex;
  width: 35%;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.dashboard_mainContainer__2-zxL {
  display: flex;
  flex-direction: row;
  height: 100%;
  font-family: "Rubik", sans-serif;
}

.dashboard_sidebarContainer__JJnHF {
  width: 15%;
  height: 100%;
  padding: 30px;
}

.dashboard_dashboardContainer__3_QHg {
  width: 85%;
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;
}

.dashboard_sidebarContainerMenu__2hQat {
  display: none;
}

.dashboard_sidebarContainerInside__2qs1- {
  padding: 30px;
}

.dashboard_sidebarMenuItemTop__3BHvu {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 30px;
  color: #de4c5d;
}

.dashboard_sidebarMenuItems__3c3KA {
  margin-top: 30px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 600;
  cursor: pointer;
}

.dashboard_sidebarMenuChild__295b8 {
  margin-left: 15px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: mediumblue;
}

.dashboard_dashboardContainerBox__1nO2Z {
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0;
  border-radius: 15px;
  box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.dashboard_dashboardTopCaption__3xhQu {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
}

.dashboard_dashboardTopPeriodCaption__O1g6_ {
  margin-bottom: 10px;
  margin-right: 20px;
}

.dashboard_dashboardTopPickerContainer__3Vo9t {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.dashboard_dashboardPickerA__1dyIA {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 15px;
  border: 1px solid white;
  margin-right: 30px;
}

.dashboard_dashboardPickerB__2DS-P {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 15px;
  border: 1px solid white;
}

.dashboard_dashboardTotalTransactionContainer__evI_j {
  display: flex;
  flex-direction: row;
}

.dashboard_dashboardTotalTransaction__gLVSY {
  font-size: 48px;
  font-weight: bold;
  margin-right: 20px;
}

.dashboard_dashboardTotalTransactionCaption__1uIDj {
  display: flex;
  width: 20px;
  margin-top: 5px;
  justify-content: left;
  align-items: center;
}

.dashboard_dashboardJumlahTotalContainer__3ZPag {
  display: flex;
  flex-direction: row;
}

.dashboard_dashboardJumlahTotalCurrency__3bP-B {
  font-size: 36px;
  font-weight: bold;
  margin-right: 10px;
}

.dashboard_dashboardJumlahTotal__25b4b {
  font-size: 48px;
  font-weight: bold;
  margin-right: 20px;
}

.dashboard_dashboardJumlahTotalCaption__2H9oO {
  width: 90px;
  margin-top: 5px;
}

.dashboard_dashboardRataInvoiceCaption__2Y9I9 {
  width: 90px;
  margin-top: 5px;
}

.dashboard_dialogDatePicker__1qmqa {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-bottom: 10px;
  width: 85%;
  align-items: flex-end;
}

.dashboard_dialogSectionName__YUESv {
  padding-top: 10px;
  font-weight: 400;
}

.dashboard_dialogBottomSection__1-t0G {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}

.dashboard_dashboardChartCaption__3O0ua {
  font-size: 32px;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.dashboard_dashboardChartContainer__2Tjn7 {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dashboard_dashboardChartLeft__3fBMt {
  width: 50%;
}

.dashboard_dashboardChartRight__1cRFQ {
  width: 50%;
}

.dashboard_dashboardChartLegendContainer__V8mW7 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.dashboard_dashboardChartLegendCircleOrange__3DLLu {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid orange;
  margin-right: 20px;
}

.dashboard_dashboardChartLegendCircleYellow__1Ax1C {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid yellow;
  margin-right: 20px;
}

.dashboard_dashboardChartLegendCircleCyan__2svgl {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid cyan;
  margin-right: 20px;
}

.dashboard_dashboardChartLegendCircleCrimson__2n8j2 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 8px solid crimson;
  margin-right: 20px;
}

.dashboard_dashboardChartLegendText__2imYe {
}

.dashboard_dashboardLegendCaption__Hayl9 {
  color: #707070;
}

.dashboard_dashboardLegendValue__pimqg {
  font-weight: bold;
}

.dashboard_periodContainerMobile__2wtLg {
  display: none;
  width: 100%;
}

.dashboard_periodMobileCaption__3VGQX {
  margin-bottom: 20px;
}

.dashboard_dashboardContainerMobile__6pR98 {
  height: 100%;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  width: 100%;
}

.dashboard_flexMobilePicker__UiEWn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dashboard_upperMillion__Jimro {
  font-size: 48px;
}

.dashboard_lowerMillion__2-g5_ {
  font-size: 28px;
  color: rgba(0, 0, 0, 0.5);
}

.dashboard_profileCompanyHeader__1Nc-j {
  font-weight: bold;
  font-size: 1rem;
}

.dashboard_profileDetailHeader__1ChxY {
  font-size: 0.8rem;
}

.dashboard_profileContainerHeader__3TDGc {
  margin-left: auto;
  display: flex;
  justify-content: right;
}

.dashboard_profileDetailContinerHeader__YKpUj {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

@media only screen and (max-width: 780px) {
  .dashboard_periodContainerMobile__2wtLg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .dashboard_logoutButton__1TeM5 {
    margin-top: 0px;
    width: 80%;
  }

  .dashboard_headerLogo__38V2R {
    margin-top: 0px;
  }

  .dashboard_periodContainerHeader__mZLRK {
    display: none;
  }

  .dashboard_dashboardContainerTop__1YOqq {
    height: 25%;
  }

  .dashboard_sidebarDrawer___rWm9 {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
  }

  .dashboard_sidebarContainerMenu__2hQat {
    display: block;
    width: 384px;
  }

  .dashboard_sidebarContainerInside__2qs1- {
    width: 240px;
  }

  .dashboard_headerContainer__1ZqeF {
    width: 100%;
  }

  .dashboard_dashboardContainer__3_QHg {
    width: 95%;
    height: 120%;
    /*padding:20px;*/
  }

  .dashboard_dashboardContainerBottomLeft__2mpqe {
    width: 94%;
    margin-bottom: 20px;
    padding: 20px 10px 20px 20px;
  }

  .dashboard_dashboardContainerBottomRight__26PBB {
    width: 94%;
    padding: 20px 10px 20px 20px;
  }

  .dashboard_sidebarContainer__JJnHF {
    display: none;
  }

  .dashboard_dashboardContainerBottom__1ebVW {
    display: block;
  }

  .dashboard_mainContainer__2-zxL {
    height: 130%;
  }
}

@media only screen and (max-width: 480px) {
  .dashboard_periodContainerMobile__2wtLg {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .dashboard_logoutButton__1TeM5 {
    margin-top: 0px;
    width: 40%;
  }

  .dashboard_headerLogo__38V2R {
    margin-top: 0px;
  }

  .dashboard_dashboardContainerTop__1YOqq {
    height: 25%;
    width: 90%;
  }

  .dashboard_sidebarDrawer___rWm9 {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
  }

  .dashboard_sidebarContainerMenu__2hQat {
    display: block;
    width: 240px;
  }

  .dashboard_sidebarContainerInside__2qs1- {
    width: 240px;
  }

  .dashboard_body__2Am-p {
    height: 120%;
  }

  .dashboard_headerContainer__1ZqeF {
    width: 95.6%;
  }

  .dashboard_dashboardContainer__3_QHg {
    width: 98%;
    height: 100%;
    /*padding:-20px;*/
  }

  .dashboard_dashboardContainerBottomLeft__2mpqe {
    width: 93%;
  }

  .dashboard_dashboardContainerBottomRight__26PBB {
    width: 93%;
  }

  .dashboard_sidebarContainer__JJnHF {
    display: none;
  }

  .dashboard_dashboardContainerBottom__1ebVW {
    display: block;
    width: 100%;
  }

  .dashboard_mainContainer__2-zxL {
    height: 110%;
  }

  .dashboard_periodContainerHeader__mZLRK {
    display: none;
    width: 100%;
  }

  .dashboard_mainContainer__2-zxL {
    height: 130%;
  }
}

@media only screen and (max-width: 361px) {
  .dashboard_dashboardContainer__3_QHg {
    width: 88.4%;
    height: 105%;
  }

  .dashboard_headerContainer__1ZqeF {
    width: 94.1%;
  }

  .dashboard_dashboardContainerTop__1YOqq {
    height: 15%;
  }

  .dashboard_dashboardTopCaption__3xhQu {
    font-size: 24px;
  }

  .dashboard_dashboardTotalTransaction__gLVSY {
    font-size: 24px;
    margin-top: 5px;
  }

  .dashboard_dashboardTotalTransactionCaption__1uIDj {
    font-size: 14px;
    margin-top: 5px;
  }

  .dashboard_upperMillion__Jimro {
    font-size: 24px;
  }

  .dashboard_lowerMillion__2-g5_ {
    font-size: 16px;
  }

  .dashboard_dashboardJumlahTotalCaption__2H9oO {
    font-size: 14px;
    margin-top: 15px;
    align-items: center;
  }

  .dashboard_dashboardJumlahTotal__25b4b {
    font-size: 24px;
    margin-top: 5px;
  }

  .dashboard_dashboardJumlahTotalCurrency__3bP-B {
    font-size: 24px;
  }

  .dashboard_dashboardChartCaption__3O0ua {
    font-size: 24px;
  }

  .dashboard_dashboardLegendCaption__Hayl9 {
    font-size: 14px;
    width: 20px;
  }

  .dashboard_dashboardRataInvoiceCaption__2Y9I9 {
    font-size: 14px;
    margin-top: 5px;
  }

  .dashboard_dialogSectionName__YUESv {
    padding-top: 10px;
    font-weight: 400;
  }

  .dashboard_dialogDatePicker__1qmqa {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-bottom: 10px;
    width: 85%;
    align-items: flex-end;
  }

  .dashboard_dialogBottomSection__1-t0G {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .dashboard_periodContainerMobile__2wtLg {
    width: 100%;
  }

  .dashboard_periodMobileCaption__3VGQX {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
}

.roundedbox {
  width: 100%;
  border-radius: 10px;
  text-align: center;
  background: #edf0f5;
  overflow: hidden;
}
.ant-table-thead > tr > th {
  color: #4d4f5c;
  background: #edf0f5 !important;
}

.white-box {
  width: 70vw;
  background-color: white;
  margin: auto;
  border-radius: 15px;
  box-shadow: 15px 15px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.ant-table table {
  font-size: 0.75vw;
}

.title-voucher {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #43425d;
  opacity: 1;
  font-family: "Rubik", sans-serif;
}
.accordion-title {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0px;
  color: #43425d;
  opacity: 1;
}
.desc-voucher {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #4d4f5c;
  text-transform: lowercase;
  opacity: 1;
  font-family: "Rubik", sans-serif;
}

.pageTitle {
  width: 100%;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: bold;
}

.white-box-usermanagement {
  width: 100%;
}

.searchBarUserManagement {
  width: 35%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.createNewUserButton {
  margin-left: 3rem !important;
}

.editAndDeleteButton {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}

.buttonSize {
  font-size: max(1rem, 1.6vw) !important;
}

.required::after {
  content:" *";
  color: red;
}

.formTitle {
  font-family: "Rubik", sans-serif;
  color: #43425d;
}

.dividerStyle {
  background-color: #afafaf !important;
}

.buttonKeepRight {
  margin-right: 3rem;
  float: right;
}

.buttonCancelMUIStyle {
  color: #f50057 !important;
  border-color: #f50057 !important;
  background: #fff !important;
  border-radius: 4px !important;
}

.buttonCreateMUIStyle {
  border-color: #f50057 !important;
  background: #f50057 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
              0px 2px 2px 0px rgb(0 0 0 / 14%), 
              0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  border-radius: 4px !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
}

.ant-radio-checked .ant-radio-inner{
  border-color: red !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: red;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red ;
}

.slashStyle {
  color: #999999;
  font-size: 1.2rem;
}

.ant-modal-content {
  border-radius: 1rem;
}

.ant-modal-content .ant-modal-body {
  text-align: center;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: none;
  display: flex;
  justify-content: space-around;
}

.ant-modal-confirm-btns .ant-btn {
  width: 8rem;
  color: #f50057 !important;
  border-color: #f50057 !important;
  background: #fff !important;
  border-radius: 4px !important;
}

.ant-modal-confirm-btns .ant-btn-dangerous {
  border-color: #f50057 !important;
  background: #f50057 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
              0px 2px 2px 0px rgb(0 0 0 / 14%), 
              0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  border-radius: 4px !important;
  color: #ffff !important;
}

@media only screen and (max-width: 780px) {
  .buttonSize {
    font-size: 1rem !important;
  }
}


.headerButton {
  float: right;
  margin-bottom: 1rem;
}

.buttonEditMUIStyle {
  color: #ffff !important;
  background: #f50057 !important;
  border-color: #f50057 !important;
  border-radius: 4px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
              0px 2px 2px 0px rgb(0 0 0 / 14%), 
              0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.buttonEditMUIStyle:disabled, .buttonEditMUIStyle[disabled]{
  border: 1px #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
.report_headerButton__12PoS {
  float: right;
  margin-bottom: 1rem;
}

.report_dialogSectionName__3gRiF {
  padding-top: 10px;
  font-weight: 400;
}

.report_dialogDatePicker__tW7kg {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-bottom: 10px;
  width: 85%;
  align-items: flex-end;
}

.report_dialogBottomSection__3BnaS {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}

@media only screen and (max-width: 361px) {
  .report_dialogSectionName__3gRiF {
    padding-top: 10px;
    font-weight: 400;
  }

  .report_dialogDatePicker__tW7kg {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-bottom: 10px;
    width: 85%;
    align-items: flex-end;
  }

  .report_dialogBottomSection__3BnaS {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }
}




.verification_container__BcUUr{
    background-image: url(../../static/media/Wallpaper.479d9cd3.png);
    background-size: cover;
    height:100%;
    text-align: center;
}
.verification_marginTop__XjhNo{
    padding-bottom:8%;
}
.verification_formContainer__3ELtp{
    margin:auto;
    background-color: white;
    border-radius:60px;
    box-shadow: 15px 15px 20px 0px rgba(0,0,0,0.75);
    width:33%;
    height:66%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.verification_otpCaption__3qud6{
    margin-top:30px;
    font-size: 16px;
    font-family:'Rubik', sans-serif;
}
p {
    margin:0px;
}
.verification_emailCaption__P0K3z{
    margin-top:50px;
    text-align: left;
    margin-right:200px;
    font-size: 16px;
    font-family:'Rubik', sans-serif;
}

.verification_emailInputText__2MSfg{
    background-color: #F1F1F1;
    border-radius:15px;
    width:250%;
    height:30px;
    margin:0px 0px 10px -80px;
    border:1px solid white;
    padding:0px 10px;
}
.verification_sendOTPButtonContainer__2cYV0{
    margin:50px 0px 0px -130px;
}

.verification_sendOTPButton__10mwN{
    background-image: linear-gradient(to right,#D6474D, #E7555A, #EE5B60, #FC7D82);
    border:1px solid white;
    height:40px;
    width:400%;
    border-radius:15px;
    margin: 0px 0px 0px 0px;
    color:white;
}

@media only screen and (max-width: 481px) {
    .verification_formContainer__3ELtp{
        width:90%;
    }
}
@media only screen and (max-width: 780px) {
    .verification_formContainer__3ELtp{
        width:65%;
    }
}

.inputOTPCode_container__3l20E{
    background-image: url(../../static/media/Wallpaper.479d9cd3.png);
    background-size: cover;
    height:100%;
    text-align: center;
}
.inputOTPCode_marginTop__36A5G{
    padding-bottom:8%;
}
.inputOTPCode_formContainer__1y8u8{
    margin:auto;
    background-color: white;
    border-radius:60px;
    box-shadow: 15px 15px 20px 0px rgba(0,0,0,0.75);
    width:33%;
    height:66%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputOTPCode_inputOTPCaption__1ZEbh{
    margin-top:30px;
    font-size: 16px;
    font-family:'Rubik', sans-serif;
}

.inputOTPCode_otpCodeContainer__2zqj3{
    display: flex;
    flex-direction: row;
}
.inputOTPCode_otpCodeTextContainer__16d0E{
    margin:10px;
    width:30px;
    font-family: 'Rubik', sans-serif;
    font-size:48px;
}
.inputOTPCode_otpCodeTextField__3IJog{
    font-family: 'Rubik', sans-serif;
    font-size:48px;
}
.inputOTPCode_sendOTPButtonContainer__3cH3E{
    margin:50px 0px 0px -130px;
}
.inputOTPCode_resendOTPCaption__3bQTS{
    margin-top:20px;
    color:#BDBDBD
}
.inputOTPCode_otpSendButton__u0BCi{
    background-image: linear-gradient(to right,#D6474D, #E7555A, #EE5B60, #FC7D82);
    border:1px solid white;
    height:40px;
    width:400%;
    border-radius:15px;
    margin: 20px 0px 0px 0px;
    color:white;
    cursor:pointer;
}
.inputOTPCode_otpSendButtonDisabled__3vr2M{
    background-image: linear-gradient(to right,#D6474D, #E7555A, #EE5B60, #FC7D82);
    border:1px solid white;
    height:40px;
    width:400%;
    border-radius:15px;
    margin: 0px 0px 0px 0px;
    color:grey;
}

@media only screen and (max-width: 481px) {
    .inputOTPCode_formContainer__1y8u8{
        width:90%;
    }
}
@media only screen and (max-width: 780px) {
    .inputOTPCode_formContainer__1y8u8{
        width:65%;
    }
}

.forgotpassword_container__2UWJx{
    background-image: url(../../static/media/Wallpaper.479d9cd3.png);
    background-size: cover;
    height:100%;
    text-align: center;
}
.forgotpassword_marginTop__E6WQY{
    padding-bottom:8%;
}

.forgotpassword_formContainer__a_eNI{
    margin:auto;
    background-color: white;
    border-radius:60px;
    box-shadow: 15px 15px 20px 0px rgba(0,0,0,0.75);
    width:33%;
    height:66%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
p{
    margin:0px;
}
.forgotpassword_forgotPasswordCaption__-FZOJ{
    font-size: 16px;
    font-family:'Rubik', sans-serif;
    margin-bottom:30px;
}
.forgotpassword_emailCaption__3UH14{
    text-align: left;
    margin-right:160px;
    font-size: 16px;
    font-family:'Rubik', sans-serif;
}
.forgotpassword_emailInputText__Vyutt{
    background-color: #F1F1F1;
    border-radius:15px;
    width:250%;
    height:30px;
    margin:0px 0px 30px -80px;
    border:1px solid white;
    padding:0px 10px;
}
.forgotpassword_sendButtonContainer__26i8e{
    margin:50px 0px 0px -130px;
}
.forgotpassword_sendButton__3_55H{
    background-image: linear-gradient(to right,#D6474D, #E7555A, #EE5B60, #FC7D82);
    border:1px solid white;
    height:40px;
    width:400%;
    border-radius:15px;
    margin: 0px 0px 0px 0px;
    color:white;
}

.changepassword_container__3hdxQ{
    background-image: url(../../static/media/Wallpaper.479d9cd3.png);
    background-size: cover;
    height:100%;
    text-align: center;
}
.changepassword_marginTop__38Qac{
    padding-bottom:8%;
}

.changepassword_formContainer__2c8hj{
    margin:auto;
    background-color: white;
    border-radius:60px;
    box-shadow: 15px 15px 20px 0px rgba(0,0,0,0.75);
    width:33%;
    height:66%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
p{
    margin:0px;
}
.changepassword_forgotPasswordCaption___rVom{
    font-size: 16px;
    font-family:'Rubik', sans-serif;
    margin-bottom:30px;
}

.changepassword_newpasswordContainer__1iYbk{
    text-align: left;
    margin-right:120px;
    font-size: 16px;
    font-family:'Rubik', sans-serif;
}

.changepassword_confirmpasswordContainer__2rYJk{
    text-align: left;
    margin-right:60px;
    font-size: 16px;
    font-family:'Rubik', sans-serif;
}
.changepassword_emailInputText__1g9Is{
    background-color: #F1F1F1;
    border-radius:15px;
    width:250%;
    height:30px;
    margin:0px 0px 30px -80px;
    border:1px solid white;
    padding:0px 10px;
}
.changepassword_sendButtonContainer__3I4Be{
    margin:50px 0px 0px -130px;
}
.changepassword_sendButton__ChF0P{
    background-image: linear-gradient(to right,#D6474D, #E7555A, #EE5B60, #FC7D82);
    border:1px solid white;
    height:40px;
    width:400%;
    border-radius:15px;
    margin: 0px 0px 0px 0px;
    color:white;
}

.loginV2_container__1SwVA {
  background: #fcfcfc;
  font-family: "Rubik", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.loginV2_header__3BPlC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fcfcfc;
  height: 10%;
  width: 100%;
  position: fixed;
}

.loginV2_headerLeft__2OVAf {
  width: 25%;
  padding: 1%;
}

.loginV2_logo__31-nn {
  margin-left: 15%;
  width: 50%;
  display: inline;
  cursor: pointer;
}

.loginV2_headerRight__36XfP {
  width: 75%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.loginV2_headerRightMenu__97lbJ {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: center;
  font-weight: bolder;
}

.loginV2_feature__23khE {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 80px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.loginV2_featureUnderline__1Xn6p {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 80px;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 5px solid #ffc600;
}

.loginV2_contactUs__2hIld {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 32px;
  font-weight: bold;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.loginV2_contactUsUnderline__1dzo4 {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 32px;
  font-weight: bold;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 5px solid #ffc600;
}

.loginV2_navItem__1w3Wc {
  color: #9a0c19;
  font-size: xx-large;
  margin: 0 40px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
}

.loginV2_loginForm__QCB3a {
  display: flex;
  margin: 0 20px;
  align-items: center;
  text-align-last: end;
}

.loginV2_loginUsernameContainer__2LmIE {
  width: 100%;
  margin-right: 5%;
}

.loginV2_loginPasswordContainer__10JMN {
  width: 100%;
  margin-right: 10px;
}

p {
  margin: 0px;
}

.loginV2_errorP__GJoar {
  width: 66%;
  text-align: center;
}

.loginV2_errorMessage__37RjO {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  color: #ff0000;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loginV2_loginUsernameInputText__1EiXr {
  color: #9a0c19 !important;
  background-color: #ffc600 !important;
  text-align-last: end !important;
  border-radius: 4px !important;
  font-weight: bolder !important;
  font-size: medium !important;
  text-align: right !important;
}

.loginV2_loginPasswordInputText__111bk {
  color: #9a0c19 !important;
  background-color: #ffc600 !important;
  text-align-last: end !important;
  border-radius: 4px !important;
  font-weight: bolder !important;
  font-size: medium !important;
  text-align: right !important;
}

.loginV2_iconLogin__1g2oU {
  width: 15%;
  max-height: 32px;
  cursor: pointer;
  margin: 1rem;
}

.loginV2_body__qgFzG {
  height: inherit;
}

.loginV2_footer__2quWU {
  background: #dddbdb;
  height: 27vh;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginV2_footerTop__3Z2mY {
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loginV2_footerLeft__1ywbi {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 2.5% 0 0 8%;
}

.loginV2_iconAddress__303ln {
  width: 90%;
}

.loginV2_footerRight__11YnE {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 3% 5% 0 0;
}

.loginV2_iconGooglePlay__2iYQR {
  height: 60%;
}

.loginV2_footerBottom__ZUgBe {
  text-align: center;
  align-self: center;
  height: 5vh;
  width: 100%;
  background: #dddbdb;
}

.loginV2_line__2Ry8r {
  margin-bottom: 0.1%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-top: 3px solid #9a0c19;
}

.loginV2_gromartClub__3Qv-T {
  height: 80%;
}

.loginV2_error__ItYg2 {
  text-align: center;
  font-size: 20px;
  background: #ddbb34;
  border: 3px solid #9a0c19;
  max-height: 200px;
}

.homePage_container__2occX {
  background: #fcfcfc;
  height: 165vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.homePage_bodyTop__2iMLE {
  padding-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #cdcdcd;
  height: 90.35vh;
}

.homePage_bodyTopLeft__zgIxh {
  width: 50%;
}

.homePage_imagePeople__3n3yn {
  height: 100%;
}

.homePage_bodyTopRight__1lsD5 {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.homePage_bodyTopRightUpper__26J9z {
  height: 70%;
}

.homePage_hereGetCloser__2wgkI {
  margin-top: 25%;
  width: 90%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.homePage_bodyTopRightLower__2Uldk {
  height: 30%;
}

.homePage_whyJoinUs2__2J3Zw {
  margin-left: 33%;
  margin-top: -18%;
  width: 60%;
}

.homePage_bodyBottom__2sj-J {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fcfcfc;
  height: 65vh;
}

.homePage_bodyBottomLeft__1SlQv {
  width: 30%;
}

.homePage_ourBusiness__2R4II {
  height: 80%;
  margin-top: 5%;
  margin-left: 20%;
}

.homePage_contact__2Tkeb {
  width: 70%;
  margin-left: 20%;
}

.homePage_bodyBottomRight__1z17J {
  width: 70%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.homePage_bodyBottomRightUpper__3w2hW {
  height: 25%;
}

.homePage_bodyBottomRightLower__2WKfJ {
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homePage_bodyBottomRightLowerChild__1QTX4 {
  text-align: center;
  width: 33.33%;
}

.homePage_marketPlace__1VuhD {
  margin: 9% 0 2% 34%;
  height: 90%;
}

.homePage_chat__srjUm {
  margin: 9% 0 2% 0;
  height: 90%;
}

.homePage_salesman__1RpqO {
  margin: 9% 34% 2% 0;
  height: 90%;
}
.homePage_mobileMode__1iogM {
  display: none;
  align-items: center;
}

@media only screen and (max-width: 780px) {
  .homePage_bodyBottom__2sj-J {
    display: none;
  }
  .homePage_mobileMode__1iogM {
    display: block;
  }
  .homePage_imageMobile__3eEXI {
    display: block;
    width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.feature_container__3EvA7 {
    padding-top: 10vh;
    height: 63vh;
    background: rgba(247, 247, 247, 1);
    display: flex;
    flex-direction: row;
}

.feature_left__3XXks {
    width: 35%;
}

.feature_text1__17q9F {
    width: 90%;
    margin-left: 20vh;
    margin-top: 10vh;
    position: relative;
}

.feature_center__2d80M {
    width: 30%;
}

.feature_right__2asDe {
    width: 35%;
}

.feature_text2__2oQXI {
    width: 79%;
    margin-top: 38vh;
    margin-left: -10vh;
}

@-webkit-keyframes feature_test__1-rsY {
    0% {
        background-image: url(../../static/media/Feature1.1bbc9469.png);
    }
    33% {
        background-image: url(../../static/media/Feature2.d4e60078.png);
    }
    66% {
        background-image: url(../../static/media/Feature3.7663e0bc.png);
    }
    100% {
        background-image: url(../../static/media/Feature1.1bbc9469.png);
    }
}

@keyframes feature_test__1-rsY {
    0% {
        background-image: url(../../static/media/Feature1.1bbc9469.png);
    }
    33% {
        background-image: url(../../static/media/Feature2.d4e60078.png);
    }
    66% {
        background-image: url(../../static/media/Feature3.7663e0bc.png);
    }
    100% {
        background-image: url(../../static/media/Feature1.1bbc9469.png);
    }
}

.feature_image__pFhSK {
    margin-top: 8vh;
    width: 40vh;
    height: 55vh;
    float: left;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-animation: feature_test__1-rsY 4s infinite;
    animation: feature_test__1-rsY 4s infinite;
    -webkit-animation-timing-function: steps(1, end);
    animation-timing-function: steps(1, end);
}
.contactUs_container__WSWJB {
    padding-top: 10vh;
    height: 63vh;
    background: rgba(247,247,247,1);
    display: flex;
    flex-direction: row;
    color: #9A0C19;
}

.contactUs_left__3fNvo {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12%;
}

p{
    font-size: xx-large;
    font-weight: bolder;
}

.contactUs_right__3rHek {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contactUs_table__10fRb {
    display: flex;
    flex-direction: column;
    font-size: larger;
    font-weight: bold;
}

.contactUs_icon__va7JE {
    margin-right: 5%;
}

.contactUs_content__3kIpS {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
}


