.container {
  background: #fcfcfc;
  height: 165vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bodyTop {
  padding-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #cdcdcd;
  height: 90.35vh;
}

.bodyTopLeft {
  width: 50%;
}

.imagePeople {
  height: 100%;
}

.bodyTopRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bodyTopRightUpper {
  height: 70%;
}

.hereGetCloser {
  margin-top: 25%;
  width: 90%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.bodyTopRightLower {
  height: 30%;
}

.whyJoinUs2 {
  margin-left: 33%;
  margin-top: -18%;
  width: 60%;
}

.bodyBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fcfcfc;
  height: 65vh;
}

.bodyBottomLeft {
  width: 30%;
}

.ourBusiness {
  height: 80%;
  margin-top: 5%;
  margin-left: 20%;
}

.contact {
  width: 70%;
  margin-left: 20%;
}

.bodyBottomRight {
  width: 70%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bodyBottomRightUpper {
  height: 25%;
}

.bodyBottomRightLower {
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bodyBottomRightLowerChild {
  text-align: center;
  width: 33.33%;
}

.marketPlace {
  margin: 9% 0 2% 34%;
  height: 90%;
}

.chat {
  margin: 9% 0 2% 0;
  height: 90%;
}

.salesman {
  margin: 9% 34% 2% 0;
  height: 90%;
}
.mobileMode {
  display: none;
  align-items: center;
}

@media only screen and (max-width: 780px) {
  .bodyBottom {
    display: none;
  }
  .mobileMode {
    display: block;
  }
  .imageMobile {
    display: block;
    width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
