.container {
    padding-top: 10vh;
    height: 63vh;
    background: rgba(247,247,247,1);
    display: flex;
    flex-direction: row;
    color: #9A0C19;
}

.left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12%;
}

p{
    font-size: xx-large;
    font-weight: bolder;
}

.right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.table {
    display: flex;
    flex-direction: column;
    font-size: larger;
    font-weight: bold;
}

.icon {
    margin-right: 5%;
}

.content {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
}

