.container {
  background: #fcfcfc;
  font-family: "Rubik", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fcfcfc;
  height: 10%;
  width: 100%;
  position: fixed;
}

.headerLeft {
  width: 25%;
  padding: 1%;
}

.logo {
  margin-left: 15%;
  width: 50%;
  display: inline;
  cursor: pointer;
}

.headerRight {
  width: 75%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.headerRightMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: center;
  font-weight: bolder;
}

.feature {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 80px;
  height: fit-content;
  cursor: pointer;
}

.featureUnderline {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 80px;
  cursor: pointer;
  height: fit-content;
  border-bottom: 5px solid #ffc600;
}

.contactUs {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 32px;
  font-weight: bold;
  height: fit-content;
  cursor: pointer;
}

.contactUsUnderline {
  color: #9a0c19;
  font-size: xx-large;
  margin-right: 32px;
  font-weight: bold;
  cursor: pointer;
  height: fit-content;
  border-bottom: 5px solid #ffc600;
}

.navItem {
  color: #9a0c19;
  font-size: xx-large;
  margin: 0 40px;
  height: fit-content;
  cursor: pointer;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
}

.loginForm {
  display: flex;
  margin: 0 20px;
  align-items: center;
  text-align-last: end;
}

.loginUsernameContainer {
  width: 100%;
  margin-right: 5%;
}

.loginPasswordContainer {
  width: 100%;
  margin-right: 10px;
}

p {
  margin: 0px;
}

.errorP {
  width: 66%;
  text-align: center;
}

.errorMessage {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  color: #ff0000;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loginUsernameInputText {
  color: #9a0c19 !important;
  background-color: #ffc600 !important;
  text-align-last: end !important;
  border-radius: 4px !important;
  font-weight: bolder !important;
  font-size: medium !important;
  text-align: right !important;
}

.loginPasswordInputText {
  color: #9a0c19 !important;
  background-color: #ffc600 !important;
  text-align-last: end !important;
  border-radius: 4px !important;
  font-weight: bolder !important;
  font-size: medium !important;
  text-align: right !important;
}

.iconLogin {
  width: 15%;
  max-height: 32px;
  cursor: pointer;
  margin: 1rem;
}

.body {
  height: inherit;
}

.footer {
  background: #dddbdb;
  height: 27vh;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerTop {
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerLeft {
  height: fit-content;
  margin: 2.5% 0 0 8%;
}

.iconAddress {
  width: 90%;
}

.footerRight {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 3% 5% 0 0;
}

.iconGooglePlay {
  height: 60%;
}

.footerBottom {
  text-align: center;
  align-self: center;
  height: 5vh;
  width: 100%;
  background: #dddbdb;
}

.line {
  margin-bottom: 0.1%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-top: 3px solid #9a0c19;
}

.gromartClub {
  height: 80%;
}

.error {
  text-align: center;
  font-size: 20px;
  background: #ddbb34;
  border: 3px solid #9a0c19;
  max-height: 200px;
}
