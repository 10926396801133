.container{
    background-image: url('./../../../support/images/Wallpaper.png');
    background-size: cover;
    height:100%;
    text-align: center;
}
.marginTop{
    padding-bottom:8%;
}
.formContainer{
    margin:auto;
    background-color: white;
    border-radius:60px;
    -webkit-box-shadow: 15px 15px 20px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 15px 15px 20px 0px rgba(0,0,0,0.75);
    box-shadow: 15px 15px 20px 0px rgba(0,0,0,0.75);
    width:33%;
    height:66%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputOTPCaption{
    margin-top:30px;
    font-size: 16px;
    font-family:'Rubik', sans-serif;
}

.otpCodeContainer{
    display: flex;
    flex-direction: row;
}
.otpCodeTextContainer{
    margin:10px;
    width:30px;
    font-family: 'Rubik', sans-serif;
    font-size:48px;
}
.otpCodeTextField{
    font-family: 'Rubik', sans-serif;
    font-size:48px;
}
.sendOTPButtonContainer{
    margin:50px 0px 0px -130px;
}
.resendOTPCaption{
    margin-top:20px;
    color:#BDBDBD
}
.otpSendButton{
    background-image: linear-gradient(to right,#D6474D, #E7555A, #EE5B60, #FC7D82);
    border:1px solid white;
    height:40px;
    width:400%;
    border-radius:15px;
    margin: 20px 0px 0px 0px;
    color:white;
    cursor:pointer;
}
.otpSendButtonDisabled{
    background-image: linear-gradient(to right,#D6474D, #E7555A, #EE5B60, #FC7D82);
    border:1px solid white;
    height:40px;
    width:400%;
    border-radius:15px;
    margin: 0px 0px 0px 0px;
    color:grey;
}

@media only screen and (max-width: 481px) {
    .formContainer{
        width:90%;
    }
}
@media only screen and (max-width: 780px) {
    .formContainer{
        width:65%;
    }
}
