.headerButton {
  float: right;
  margin-bottom: 1rem;
}

.buttonEditMUIStyle {
  color: #ffff !important;
  background: #f50057 !important;
  border-color: #f50057 !important;
  border-radius: 4px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
              0px 2px 2px 0px rgb(0 0 0 / 14%), 
              0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.buttonEditMUIStyle:disabled, .buttonEditMUIStyle[disabled]{
  border: 1px #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}