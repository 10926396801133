.headerButton {
  float: right;
  margin-bottom: 1rem;
}

.dialogSectionName {
  padding-top: 10px;
  font-weight: 400;
}

.dialogDatePicker {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-bottom: 10px;
  width: 85%;
  align-items: flex-end;
}

.dialogBottomSection {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}

@media only screen and (max-width: 361px) {
  .dialogSectionName {
    padding-top: 10px;
    font-weight: 400;
  }

  .dialogDatePicker {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-bottom: 10px;
    width: 85%;
    align-items: flex-end;
  }

  .dialogBottomSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }
}


